/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type businessState_failedBankConnections$ref = any;
export type ExperimentName = "BANK_ACCOUNT_INTEGRATION" | "CREATE_YOUR_FIRST_RECORD" | "INVOICING_TOOL" | "NEW_MAIN_PAGE" | "ONBOARDING_TOUR" | "TAX_CONSULTANT_MENU_BUTTON" | "TRIAL_FOR_VAT_NEW_FREELANCERS" | "%future added value";
export type TaxAdvisorLanguageSettingsLanguage = "DE" | "EN" | "%future added value";
export type TaxNeeds = "invoicing" | "old_business" | "register_as_a_freelancer" | "reports" | "tax_question" | "%future added value";
export type VatExemptionParagraph = "paragraph_1" | "paragraph_10" | "paragraph_11" | "paragraph_11a" | "paragraph_11b" | "paragraph_12" | "paragraph_13" | "paragraph_14" | "paragraph_15" | "paragraph_15a" | "paragraph_15b" | "paragraph_15c" | "paragraph_16" | "paragraph_17" | "paragraph_18" | "paragraph_18a" | "paragraph_2" | "paragraph_20" | "paragraph_21" | "paragraph_22" | "paragraph_23" | "paragraph_24" | "paragraph_25" | "paragraph_26" | "paragraph_27" | "paragraph_28" | "paragraph_29" | "paragraph_3" | "paragraph_4" | "paragraph_4a" | "paragraph_4b" | "paragraph_5" | "paragraph_6" | "paragraph_7" | "paragraph_8" | "paragraph_9" | "%future added value";
export type businessStateQueryVariables = {|
  languageCode?: ?string,
  languageSettingsLanguageCode?: ?TaxAdvisorLanguageSettingsLanguage,
|};
export type businessStateQueryResponse = {|
  +currentBusiness: ?{|
    +id: string,
    +name: ?string,
    +showTaxCredit: boolean,
    +taxNeedsOnboardingQuestion: ?TaxNeeds,
    +defaultVatExemptionParagraph: ?VatExemptionParagraph,
    +assignedTaxAdvisor: ?{|
      +taxAdvisory: {|
        +name: string,
        +termsAndConditionsUrl: ?string,
      |},
      +name: string,
      +pictureUrl: ?string,
      +languagesSettings: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +language: TaxAdvisorLanguageSettingsLanguage,
            +bio: string,
            +videoUrl: ?string,
            +calendlyUrl: string,
            +calendlyLongCallUrl: ?string,
          |}
        |}>
      |},
      +supportedLanguages: {|
        +edges: $ReadOnlyArray<?{|
          +node: ?{|
            +language: TaxAdvisorLanguageSettingsLanguage
          |}
        |}>
      |},
    |},
    +owningUser: ?{|
      +email: string,
      +pictureUrl: ?string,
      +username: string,
    |},
    +experiments: ?{|
      +edges: $ReadOnlyArray<?{|
        +node: ?{|
          +name: ExperimentName
        |}
      |}>
    |},
    +$fragmentRefs: businessState_failedBankConnections$ref,
  |},
  +isFirstLogin: ?boolean,
  +isFirstSession: ?boolean,
  +userAssumingCapabilities: ?string,
  +activeSubscription: ?{|
    +name: ?string
  |},
  +latestSubscription: ?{|
    +isSubscriptionCanceled: ?boolean
  |},
  +failedPayment: ?{|
    +reason: ?string
  |},
  +userSettings: ?{|
    +languageCode: string
  |},
|};
export type businessStateQuery = {|
  variables: businessStateQueryVariables,
  response: businessStateQueryResponse,
|};
*/


/*
query businessStateQuery(
  $languageCode: String
  $languageSettingsLanguageCode: TaxAdvisorLanguageSettingsLanguage
) {
  currentBusiness {
    id
    name
    showTaxCredit
    taxNeedsOnboardingQuestion
    defaultVatExemptionParagraph
    ...businessState_failedBankConnections
    assignedTaxAdvisor(setDefaultLanguage: $languageCode) {
      taxAdvisory {
        name
        termsAndConditionsUrl
        id
      }
      name
      pictureUrl
      languagesSettings(language: $languageSettingsLanguageCode) {
        edges {
          node {
            language
            bio
            videoUrl
            calendlyUrl
            calendlyLongCallUrl
            id
          }
        }
      }
      supportedLanguages: languagesSettings {
        edges {
          node {
            language
            id
          }
        }
      }
      id
    }
    owningUser {
      email
      pictureUrl
      username
      id
    }
    experiments {
      edges {
        node {
          name
          id
        }
      }
    }
  }
  isFirstLogin
  isFirstSession
  userAssumingCapabilities
  activeSubscription {
    name
  }
  latestSubscription {
    isSubscriptionCanceled
  }
  failedPayment {
    reason
  }
  userSettings(setDefaultLanguage: $languageCode) {
    languageCode
    id
  }
}

fragment businessState_failedBankConnections on BusinessType {
  failedBankConnectionsCount
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "languageCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "languageSettingsLanguageCode"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showTaxCredit",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "taxNeedsOnboardingQuestion",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultVatExemptionParagraph",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "setDefaultLanguage",
    "variableName": "languageCode"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "termsAndConditionsUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pictureUrl",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "language",
    "variableName": "languageSettingsLanguageCode"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bio",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "videoUrl",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendlyUrl",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "calendlyLongCallUrl",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v17 = [
  (v2/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "failedBankConnectionsCount",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFirstLogin",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isFirstSession",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userAssumingCapabilities",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "activeSubscription",
  "plural": false,
  "selections": (v17/*: any*/),
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "Subscription",
  "kind": "LinkedField",
  "name": "latestSubscription",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isSubscriptionCanceled",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "concreteType": "Payment",
  "kind": "LinkedField",
  "name": "failedPayment",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "languageCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "businessStateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessType",
        "kind": "LinkedField",
        "name": "currentBusiness",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "TaxAdvisorType",
            "kind": "LinkedField",
            "name": "assignedTaxAdvisor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxAdvisoryType",
                "kind": "LinkedField",
                "name": "taxAdvisory",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "TaxAdvisorLanguageTypeConnection",
                "kind": "LinkedField",
                "name": "languagesSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaxAdvisorLanguageTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaxAdvisorLanguageType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "supportedLanguages",
                "args": null,
                "concreteType": "TaxAdvisorLanguageTypeConnection",
                "kind": "LinkedField",
                "name": "languagesSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaxAdvisorLanguageTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaxAdvisorLanguageType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "owningUser",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v8/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExperimentTypeConnection",
            "kind": "LinkedField",
            "name": "experiments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExperimentTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExperimentType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": (v17/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineDataFragmentSpread",
            "name": "businessState_failedBankConnections",
            "selections": [
              (v18/*: any*/)
            ]
          }
        ],
        "storageKey": null
      },
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/),
      (v23/*: any*/),
      (v24/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "UserSettingsType",
        "kind": "LinkedField",
        "name": "userSettings",
        "plural": false,
        "selections": [
          (v25/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "businessStateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessType",
        "kind": "LinkedField",
        "name": "currentBusiness",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "TaxAdvisorType",
            "kind": "LinkedField",
            "name": "assignedTaxAdvisor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaxAdvisoryType",
                "kind": "LinkedField",
                "name": "taxAdvisory",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v7/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "TaxAdvisorLanguageTypeConnection",
                "kind": "LinkedField",
                "name": "languagesSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaxAdvisorLanguageTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaxAdvisorLanguageType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "supportedLanguages",
                "args": null,
                "concreteType": "TaxAdvisorLanguageTypeConnection",
                "kind": "LinkedField",
                "name": "languagesSettings",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaxAdvisorLanguageTypeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaxAdvisorLanguageType",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserType",
            "kind": "LinkedField",
            "name": "owningUser",
            "plural": false,
            "selections": [
              (v15/*: any*/),
              (v8/*: any*/),
              (v16/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExperimentTypeConnection",
            "kind": "LinkedField",
            "name": "experiments",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExperimentTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExperimentType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v19/*: any*/),
      (v20/*: any*/),
      (v21/*: any*/),
      (v22/*: any*/),
      (v23/*: any*/),
      (v24/*: any*/),
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "UserSettingsType",
        "kind": "LinkedField",
        "name": "userSettings",
        "plural": false,
        "selections": [
          (v25/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad386eb4ff16e067b2ae1dd04e73e459",
    "id": null,
    "metadata": {},
    "name": "businessStateQuery",
    "operationKind": "query",
    "text": "query businessStateQuery(\n  $languageCode: String\n  $languageSettingsLanguageCode: TaxAdvisorLanguageSettingsLanguage\n) {\n  currentBusiness {\n    id\n    name\n    showTaxCredit\n    taxNeedsOnboardingQuestion\n    defaultVatExemptionParagraph\n    ...businessState_failedBankConnections\n    assignedTaxAdvisor(setDefaultLanguage: $languageCode) {\n      taxAdvisory {\n        name\n        termsAndConditionsUrl\n        id\n      }\n      name\n      pictureUrl\n      languagesSettings(language: $languageSettingsLanguageCode) {\n        edges {\n          node {\n            language\n            bio\n            videoUrl\n            calendlyUrl\n            calendlyLongCallUrl\n            id\n          }\n        }\n      }\n      supportedLanguages: languagesSettings {\n        edges {\n          node {\n            language\n            id\n          }\n        }\n      }\n      id\n    }\n    owningUser {\n      email\n      pictureUrl\n      username\n      id\n    }\n    experiments {\n      edges {\n        node {\n          name\n          id\n        }\n      }\n    }\n  }\n  isFirstLogin\n  isFirstSession\n  userAssumingCapabilities\n  activeSubscription {\n    name\n  }\n  latestSubscription {\n    isSubscriptionCanceled\n  }\n  failedPayment {\n    reason\n  }\n  userSettings(setDefaultLanguage: $languageCode) {\n    languageCode\n    id\n  }\n}\n\nfragment businessState_failedBankConnections on BusinessType {\n  failedBankConnectionsCount\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '016d46365892e081296e8bb61cf8cf3d';

module.exports = node;
