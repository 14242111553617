import React from 'react';
import analytics from "../utils/analytics";
import IntlMessages from "../utils/IntlMessages";
import { sendHelpMail } from '../utils/contact';

const ContactUsLink = ({referer, linkText, message}) => {
  function linkClicked() {
    analytics.trackEvent(`Contact us clicked from ${referer}`);
    sendHelpMail({ body: message });
  }

  return (
    <span className='text-primary clickable' rel="noopener noreferrer" onClick={linkClicked}>
      {linkText || <IntlMessages id="general.contactUs"/>}
    </span>
  );
};


export default ContactUsLink;
