import _ from "lodash";
import {useEffect, useRef} from "react";
import moment from "moment";
import stringify from "json-stable-stringify";

export const getBusinessDefaultCurrency = () => {
  return "EUR";
};

export const isDev = () => {
  return process.env.REACT_APP_IS_DEV === 'true';
};

export const isOfflineMode = () => {
  return process.env.REACT_APP_OFFLINE_MODE === 'true';
};

export const isMobile = () => {
  return window.innerWidth < 768;
};


export function getRandomString() {
  return Math.random().toString(36).substring(7);
}

export const isEqualWithTolerance = (v1, v2, epsilon) => {
  if (epsilon == null) {
    epsilon = 0.01;
  }
  return Math.abs(v1 - v2) < epsilon;
};

export function getBase64File(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve([reader.result, file.name]);
    };
    reader.onerror = error => reject(error);
  });

}


export function getFullUrlToPath(path) {
  // eslint-disable-next-line
  return location.protocol + '//' + location.host + path
}

export function intersect(a, b) {
  return [...new Set(a)].filter(x => new Set(b).has(x));
}

export function getErrorCodeFromMutationError(errors) {
  if (errors[0].data[0] && errors[0].data[0].message) {
    return errors[0].data[0].message;
  } else {
    return null;
  }
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function getDateFromDateTime(dateTimeObj) {
  return dateTimeObj ? moment(dateTimeObj).format('YYYY-MM-DD') : null;
}

export function getDateTimeObjFromDateStr(dateStr) {
  return dateStr ? moment(dateStr, 'YYYY-MM-DD').toDate() : null;
}

export function stripNonNumeric(string) {
  return string.replace(/\D/g, '');
}

export function stripNumericAfterSpace(string) {
  return string.replace(/ [0-9]+/g, '');
}

export function stripNumeric(string) {
  return string.replace(/[0-9]+/g, '');
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export function capitalizeFirstChar(word) {
  if (word.length === 0) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function trimEdges(fieldObj) {
  return _.map(fieldObj.edges, 'node');
}

export function trimEdgesDeep(fieldObj) {
  if (_.isArray(fieldObj)) {
    return _.map(fieldObj, item => trimEdgesDeep(item));
  }
  if (_.isPlainObject(fieldObj)) {
    if (fieldObj.edges) {
      return _.map(fieldObj.edges, edge => trimEdgesDeep(edge.node));
    }
    return _.mapValues(fieldObj, value => trimEdgesDeep(value));
  }
  return fieldObj;
}

export function hashString(string) {
  let hash = 0; let i; let chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function getNextDateByDayOfMonth(dayOfMonth) {
  const today = moment();
  const relevantMonth = today.date() < dayOfMonth ? today : today.add(1, 'M');
  const daysInMonth = relevantMonth.daysInMonth();
  return new Date(relevantMonth.year(), relevantMonth.month(), dayOfMonth <= daysInMonth ? dayOfMonth : daysInMonth);
}

export function getAge(birthDate) {
  return moment().diff(birthDate, 'years');
}

export function getYearMonthFromDateStr(dateStr) {
  if (!dateStr) {
    return {year: null, month: null};
  }

  const parsedDate = getDateTimeObjFromDateStr(dateStr);
  return {year: parsedDate.getFullYear(), month: parsedDate.getMonth()};
}

export function getSmallBusinessOwnerThreshold(year = 2020) {
  if (year < 2020) {
    return 17500;
  }

  if (year < 2025) {
    return 22000;
  }

  return 25000;
}

export function compareDataObjects(obj1, obj2) {
  return stringify(obj1) === stringify(obj2);
}

export function isOnlyEmptyValues(obj) {
  return _.every(obj, value => _.isNil(value) || value === '');
}

export const getCurrentYear = () => new Date().getFullYear();

export function getLandingPageUrl(languageCode) {
  if (languageCode === "de") {
    return "https://www.getsorted.de";
  }
  return "https://en.getsorted.de/";
}

export function getTodayDate() {
  return getDateFromDateTime(new Date());
}
