const SORTED_CONTACT_MAIL = "sorted@deel.com";

export const sendHelpMail = async ({ subject, message }) => {
    let mailToLink = `mailto:${SORTED_CONTACT_MAIL}`;
    const params = [];

    if (subject) {
        params.push(`subject=${encodeURIComponent(subject)}`);
    }

    if (message) {
        params.push(`body=${encodeURIComponent(message)}`);
    }

    if (params.length > 0) {
        mailToLink += `?${params.join('&')}`;
    }

    const link = document.createElement("a");
    link.href = mailToLink;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
};
