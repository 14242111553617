// @flow
import React, {useEffect, useState} from 'react';
import graphql from 'babel-plugin-relay/macro';
import {fetchQuery, readInlineData} from "react-relay";
import IntroduceReferralCodeReceivedMutation from "./IntroduceReferralCodeReceivedMutation";
import _ from "lodash";
import {reportError} from "./utils/errorReporting";
import {getDefaultLanguage, storeLanguageCodeInLocalStorage} from "./utils/languageUtils";
import {trimEdges, trimEdgesDeep} from "./utils/Utils";
import {useAuth} from "./auth/AuthProvider";
import {useRelayEnvironment} from "react-relay/lib/hooks";
import {TaxAdvisorLanguageSettingsLanguageValues} from "./schema/generated/graphql";

function updateReceivedReferralCode(env, referralCode) {
  if (referralCode) {
    IntroduceReferralCodeReceivedMutation(env, {referralCode}, (response, errors) => {
      if (!_.isEmpty(errors)) {
        reportError(new Error("Store Referral Code Error"), {referralCode});
      }
    });
  }
}

const bankConnectionStateFragment = graphql`
    fragment businessState_failedBankConnections on BusinessType @inline {
        failedBankConnectionsCount
    }
`;

const defaultLanguage = getDefaultLanguage();

// TODO: put back when supporting flow
// declare type BusinessContextType = {
//   intercomUserId: string,
//   businessId: string,
//   setBusinessId: string,
//   currentBusinessName: string,
//   getNonAssumedBusinessName: string,
//   setCurrentBusinessName: string,
//   setFinishedOnboarding: (boolean) => string,
//   finishedOnboarding: boolean,
//   taxNeedsOnboardingQuestion: TaxNeeds,
//   setTaxNeedsOnboardingQuestion: (TaxNeeds) => void,
//   assignedTaxAdvisor: TaxAdvisorType,
//   taxAdvisorLanguageSettings: TaxAdvisorLanguageTypeEdge,
//   taxAdvisorSupportedLanguages: [TaxAdvisorLanguageSettingsLanguage],
//   isLoadingBusinessState: boolean,
//   isFirstLogin: boolean,
//   isFirstSession: boolean,
//   setAssumedBusinessId: (string => void),
//   businessEmail: string,
//   username: string,
//   businessStateLoaded: boolean,
//   showTaxCredit: boolean,
//   assumedBusinessId: string,
//   userAssumingCapabilities: string,
//   profilePicture: string,
//   disableUserAssuming: () => void,
//   activeSubscriptionName: string,
//   languageCode: string,
//   setLanguageCode: (string, boolean) => void,
//   experiments: [ExperimentName],
//   businessDisplayName: string,
//   defaultVatExemptionParagraph: VatExemptionParagraph,
//   setDefaultVatExemptionParagraph: (VatExemptionParagraph) => void
// };
// const BusinessContext = React.createContext<BusinessContextType>({});

export let globalAssumedBusinessId = null;
const BusinessContext = React.createContext({});
export const BusinessProvider = ({
  children,
}) => {
  const [businessId, setBusinessId] = useState(null);
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [isFirstSession, setIsFirstSession] = useState(false);
  const [businessStateLoaded, setBusinessStateLoaded] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [languageCode, setLanguageCodeInner] = useState(defaultLanguage);
  const [isLoadingBusinessState, setIsLoadingBusinessState] = useState(true);
  const [assumedBusinessId, setAssumedBusinessIdInner] = useState();
  const [businessEmail, setBusinessEmail] = useState();
  const [username, setUsername] = useState();
  const [taxNeedsOnboardingQuestion, setTaxNeedsOnboardingQuestion] = useState();
  const [currentBusinessName, setCurrentBusinessName] = useState();
  const [showTaxCredit, setShowTaxCredit] = useState(null);
  const [assignedTaxAdvisor, setAssignedTaxAdvisor] = useState(null);
  const [defaultVatExemptionParagraph, setDefaultVatExemptionParagraph] = useState(null);
  const [bankFailureCount, setBankFailureCount] = useState(null);
  const [userAssumingCapabilities, setUserAssumingCapabilities] = useState(null);
  const [activeSubscriptionName, setActiveSubscriptionName] = useState(null);
  const [experiments, setExperiments] = useState([]);
  const [isLatestSubscriptionCanceled, setIsLatestSubscriptionCanceled] = useState(false);
  const [failedPaymentReason, setFailedPaymentReason] = useState(null);
  const {loading, isAuthenticated, isAnonymous, referralCode, deleteAnonymousToken} = useAuth();
  const env = useRelayEnvironment();


  function setLanguageCode(languageCode, updateLocalStorage) {
    setLanguageCodeInner(languageCode);
    if (updateLocalStorage) {
      storeLanguageCodeInLocalStorage(languageCode);
    }
  }

  const updateFailedBankConnections = (business) => {
    const {failedBankConnectionsCount} = readInlineData(bankConnectionStateFragment, business);
    setBankFailureCount(failedBankConnectionsCount);
  };

  useEffect(() => {
    const storedAssumedBusinessId = localStorage.getItem('assumedBusinessId');
    if (storedAssumedBusinessId) {
      setAssumedBusinessId(storedAssumedBusinessId);
    }

  }, []);


  useEffect(() => {
    if (!loading && !isAuthenticated) {
      // if user has to login, we disable the assuming, in case he will log in from another account
      disableUserAssuming();
    }

  }, [isAuthenticated, loading]);


  useEffect(() => {
    if (!loading && (isAuthenticated || isAnonymous)) {
      fetchQuery(env, graphql`
          query businessStateQuery($languageCode: String, $languageSettingsLanguageCode: TaxAdvisorLanguageSettingsLanguage) {
              currentBusiness{
                  id
                  name
                  showTaxCredit
                  taxNeedsOnboardingQuestion
                  defaultVatExemptionParagraph
                  ...businessState_failedBankConnections
                  assignedTaxAdvisor(setDefaultLanguage:$languageCode) {
                      taxAdvisory {
                          name
                          termsAndConditionsUrl
                      }
                      name
                      pictureUrl
                      languagesSettings(language:$languageSettingsLanguageCode) {
                          edges {
                              node {
                                  language
                                  bio
                                  videoUrl
                                  calendlyUrl
                                  calendlyLongCallUrl
                              }
                          }
                      }
                      supportedLanguages: languagesSettings {
                          edges {
                              node {
                                  language
                              }
                          }
                      }
                  }
                  owningUser{
                      email
                      pictureUrl
                      username
                  }
                  experiments {
                      edges {
                          node {
                              name
                          }
                      }
                  }
              }
              isFirstLogin
              isFirstSession
              userAssumingCapabilities
              activeSubscription{
                  name
              }
              latestSubscription {
                  isSubscriptionCanceled
              }
              failedPayment {
                  reason
              }
              userSettings(setDefaultLanguage:$languageCode) {
                  languageCode
              }
          }
      `, {languageCode, languageSettingsLanguageCode: defaultLanguage === "de" ? TaxAdvisorLanguageSettingsLanguageValues.De : TaxAdvisorLanguageSettingsLanguageValues.En}).then(res => {

        setIsFirstLogin(res.isFirstLogin);
        setIsFirstSession(res.isFirstSession);
        setBusinessId(res.currentBusiness.id);
        setCurrentBusinessName(res.currentBusiness.name);
        setTaxNeedsOnboardingQuestion(res.currentBusiness.taxNeedsOnboardingQuestion);
        setAssignedTaxAdvisor(trimEdgesDeep(res.currentBusiness.assignedTaxAdvisor));
        setUserAssumingCapabilities(res.userAssumingCapabilities);
        setShowTaxCredit(res.currentBusiness.showTaxCredit);
        setBusinessEmail(res.currentBusiness.owningUser.email);
        setProfilePicture(res.currentBusiness.owningUser.pictureUrl);
        setUsername(res.currentBusiness.owningUser.username);
        setActiveSubscriptionName(res.activeSubscription?.name);
        setExperiments(_.map(trimEdges(res.currentBusiness.experiments), 'name'));
        setBusinessStateLoaded(true);
        setDefaultVatExemptionParagraph(res.currentBusiness.defaultVatExemptionParagraph);
        updateFailedBankConnections(res.currentBusiness);
        setLanguageCode(res.userSettings.languageCode);
        setIsLatestSubscriptionCanceled(res.latestSubscription?.isSubscriptionCanceled);
        setFailedPaymentReason(res.failedPayment?.reason);

        if (res.isFirstSession) {
          updateReceivedReferralCode(env, referralCode);
        }
        if (isAuthenticated) {
          deleteAnonymousToken();
        }
      }).finally(() => {
        setIsLoadingBusinessState(false);
      });
    }

  }, [
    env, loading, isAuthenticated, isAnonymous, referralCode, languageCode, deleteAnonymousToken,
    // assumedBusinessId is not used by this effect hook function but it influences the BE response
    assumedBusinessId,
  ]);

  function setAssumedBusinessId(businessId) {
    setAssumedBusinessIdInner(businessId);
    globalAssumedBusinessId = businessId;
    localStorage.setItem('assumedBusinessId', businessId);
  }

  function disableUserAssuming() {
    setAssumedBusinessIdInner(null);
    globalAssumedBusinessId = null;
    localStorage.removeItem('assumedBusinessId');
  }

  function getNonAssumedBusinessName() {
    return assumedBusinessId ? null : currentBusinessName;
  }

  const taxAdvisorLanguageSettings = assignedTaxAdvisor?.languagesSettings[0];
  const taxAdvisorSupportedLanguages = _.map(assignedTaxAdvisor?.supportedLanguages, languageSetting => languageSetting.language);
  const businessDisplayName = currentBusinessName || businessEmail || username;

  return <BusinessContext.Provider value={{
    businessId,
    setBusinessId,
    currentBusinessName,
    getNonAssumedBusinessName,
    setCurrentBusinessName,
    taxNeedsOnboardingQuestion,
    setTaxNeedsOnboardingQuestion,
    assignedTaxAdvisor,
    taxAdvisorLanguageSettings,
    taxAdvisorSupportedLanguages,
    isLoadingBusinessState,
    isFirstLogin,
    isFirstSession,
    setAssumedBusinessId,
    businessEmail,
    username,
    businessStateLoaded,
    showTaxCredit,
    assumedBusinessId,
    userAssumingCapabilities,
    profilePicture,
    disableUserAssuming,
    activeSubscriptionName,
    languageCode,
    setLanguageCode,
    experiments,
    businessDisplayName,
    defaultVatExemptionParagraph,
    setDefaultVatExemptionParagraph,
    bankFailureCount,
    updateFailedBankConnections,
    setActiveSubscriptionName,
    isLatestSubscriptionCanceled,
    failedPaymentReason
  }}> {children} </BusinessContext.Provider>;

};
export default BusinessContext;
